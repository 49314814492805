
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class PasswordChange extends Mixins(FormValidator) {
  protected formData = {
    password: "",
    password_repeat: "",
    password_current: ""
  };

  private updateData() {
    if (
      !this.formData.password ||
      this.formData.password.trim() == "" ||
      this.formData.password.length < 6
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.password_update").toString(),
        text: this.$t("messages.password_length_new").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (
      !this.formData.password_repeat ||
      this.formData.password_repeat.trim() == "" ||
      this.formData.password_repeat.length < 6
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.password_update").toString(),
        text: this.$t("messages.password_length_repeat").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (this.formData.password != this.formData.password_repeat) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.password_update").toString(),
        text: this.$t("messages.not_match_password").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    } else if (
      !this.formData.password_current ||
      this.formData.password_current.trim() == "" ||
      this.formData.password_current.length < 6
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.password_update").toString(),
        text: this.$t("messages.password_length_actual").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .put("user/password", {
        plainPassword: this.formData.password,
        password: this.formData.password_current
      })
      .then(res => {
        this.$q.loading.hide();
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$router.push({ name: "Talleres" });
      })
      .catch(error => {
        this.$q.loading.hide();
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.password_update").toString(),
          text: this.$t(
            `notifications.error.${error.response.data.message}`
          ).toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      });
  }
}
