
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import PasswordChange from "@/components/auth/PasswordChange.vue";

@Component({
  components: {
    PasswordChange
  }
})
export default class ConfiguracionComponent extends Mixins(FormValidator) {}
