
import { Component, Vue } from "vue-property-decorator";
import ConfiguracionComponent from "@/components/menu/ConfiguracionComponent.vue";

@Component({
  components: {
    ConfiguracionComponent
  }
})
export default class ConfiguracionView extends Vue {}
