
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import Debounce from "debounce-decorator";
import jsPDF from "jspdf";

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    FormValidator
  }
})
export default class MisTalleres extends Mixins(FormValidator) {
  private pagination = {
    sortBy: "nombre_taller",
    descending: false,
    page: 1,
    rowsPerPage: 25
  };
  private columns = [
    {
      name: "nombre_taller",
      required: true,
      label: "Nombre",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => row.nombre_taller,
      sortable: true
    },
    {
      name: "fecha_inicio",
      required: true,
      label: "Fecha de Inicio",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => this.formatFecha(row.fecha_inicio),
      sortable: true
    },
    {
      name: "fecha_termino",
      required: true,
      label: "Fecha de Término",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => this.formatFecha(row.fecha_termino),
      sortable: true
    },
    {
      name: "tipo",
      required: true,
      label: "Tipo",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => this.getTipo(row),
      sortable: true
    },
    {
      name: "estado",
      required: true,
      label: "Estado",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => this.getEstado(row),
      sortable: true
    },
    {
      name: "actions",
      required: true,
      label: "Acciones",
      align: "center",
      classes: "td-no-line-break",
      field: (row: any) => "--",
      sortable: false
    }
  ];
  private columnsFamiliar = [
    {
      name: "rut_familiar",
      required: true,
      label: "Rut",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => row.rut,
      sortable: true
    },
    {
      name: "nombre_familiar",
      required: true,
      label: "Nombre",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => row.nombre,
      sortable: true
    },
    {
      name: "estado",
      required: true,
      label: "Estado",
      align: "left",
      classes: "td-line-break text-principal-tres",
      field: (row: any) => this.getEstadoFamiliar(row),
      sortable: true
    },
    {
      name: "actions",
      required: true,
      label: "Acciones",
      align: "center",
      classes: "td-no-line-break",
      field: (row: any) => "--",
      sortable: false
    }
  ];
  private misTalleres: any = null;
  private misTalleresFiltrado: any = null;
  private model = null;
  private tipo = 0;
  private text = "";
  private confirmarAnular = false;
  private selectedInscripcion = 0;
  private selectedAnular = "";
  private selectedNombreTaller = "";
  private showMotivo = false;
  private showFamiliares = false;
  private dataFamiliares: any = null;
  private motivoRechazo = "";
  private canvas: any = null;
  private cardImage = new Image();
  private img = new Image();

  private selectTipo: Tipos = {
    value: null,
    label: this.$t("fields.buscar-tipo").toString()
  };
  private selectEstado: Tipos = {
    value: 1,
    label: "Vigentes"
  };

  private optionsEstado: Array<any> = [
    {
      label: "Vigentes",
      value: 1
    },
    {
      label: "Pasados",
      value: 2
    }
  ];

  private options: Array<any> = [
    {
      label: this.$t("fields.all").toString(),
      value: null
    },
    {
      label: this.$t("fields.workshop").toString(),
      value: 2
    },
    {
      label: this.$t("fields.actividades").toString(),
      value: 1
    },
    {
      label: "Servicios",
      value: 3
    },
    {
      label: "Actividad masiva",
      value: 4
    }
  ];

  @Watch("selectTipo")
  private selectModalidad(newValue: any) {
    this.filtrarTalleres();
  }

  @Watch("selectEstado")
  private watchSelectEstado(newValue: any) {
    this.filtrarTalleres();
  }
  "";
  @Watch("text")
  private watchText(newValue: any) {
    this.filtrarTalleres();
  }

  private mounted() {
    if (
      this.$store.state.user.id &&
      this.$store.state.user.id != "" &&
      (!this.$store.state.user.fecha_nacimiento ||
        this.$store.state.user.sexo == null)
    ) {
      this.$swal({
        icon: "warning",
        title: "Completar Datos",
        text: "Para una mejor experiencia, completar los datos faltantes",
        html: "Para una mejor experiencia, completar los datos faltantes",
        allowOutsideClick: false,
        confirmButtonColor: "#346a82",
        cancelButtonColor: "#d33",
        focusConfirm: false,
        confirmButtonText:
          '<a style="background-color: #346a82; color: #fff;"  href="/editar">Completar Datos</a>'
      });
    }

    if (
      this.$store.state.from_login.name == "PerfilTaller" &&
      this.$store.state.from_login.parameters != ""
    ) {
      let idTaller = this.$store.state.from_login.parameters;
      this.$store.commit("SET_FROM_LOGIN", { name: "", parameters: "" });
      this.$router
        .push({
          name: "PerfilTaller",
          params: { id: idTaller }
        })
        .catch(err => {
          console.log(err);
        });
    }
    this.getListTaller();
  }

  private getListTaller() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("mis/talleres")
      .then((res: any) => {
        this.misTalleres = res.data;
        if (this.misTalleres && this.misTalleres.length > 0) {
          this.misTalleresFiltrado = [];
        }
        for (let i = 0; i < this.misTalleres.length; i++) {
          this.misTalleres[i].foto =
            "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
            this.misTalleres[i].foto;
          if (this.misTalleres[i].vigente) {
            this.misTalleresFiltrado.push(this.misTalleres[i]);
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.misTalleres = [];
        this.misTalleresFiltrado = [];
        console.log(err);
      });
  }
  private verMotivo(data) {
    this.selectedNombreTaller = data.nombre_taller;
    this.motivoRechazo = data.motivo;
    this.showFamiliares = false;
    this.showMotivo = true;
  }

  private anularInscripcion(data) {
    this.confirmarAnular = true;
    this.selectedNombreTaller = data.nombre_taller;
    this.selectedInscripcion = data.idTaller_horario;
    this.selectedAnular = data.rut;
    this.showFamiliares = false;
  }
  private confimarAnular() {
    this.confirmarAnular = false;
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .put("anular/inscripcion", {
        id_taller_horario: this.selectedInscripcion,
        rut_familiar_anula: this.selectedAnular
      })
      .then((res: any) => {
        this.$q.loading.hide();
        this.$swal({
          icon: "success",
          allowOutsideClick: false,
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.cancel").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        }).then(result => {
          if (result.value) {
            if (this.$router.currentRoute.name == "MisTalleres") {
              this.$router.go(0);
            } else {
              this.$router.push({ name: "MisTalleres" }).catch(err => {});
            }
          }
        });
      })
      .catch((error: any) => {
        this.$q.loading.hide();
        const message = error.response.data.message;
        const type = error.response.data.type;
        let text: string = "";
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.error.ERROR_FORM").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
      });
  }
  @Debounce(500)
  private filtrarTalleres() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.misTalleresFiltrado = [];
    this.misTalleres.forEach(taller => {
      let bCumple = true;
      // Filtro por nombre
      if (this.text && this.text != "") {
        let filtro_text = this.text
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let nombre_normalizado = taller.nombre_taller
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (
          !nombre_normalizado.toLowerCase().includes(filtro_text.toLowerCase())
        ) {
          bCumple = false;
        }
      }
      // Filtro por modalidad
      if (this.selectTipo.value) {
        if (this.selectTipo.value == 1) {
          if (taller.modalidad != this.selectTipo.value) {
            bCumple = false;
          }
        } else if (this.selectTipo.value == 2) {
          if (taller.modalidad != 0) {
            bCumple = false;
          }
        } else if (this.selectTipo.value == 3) {
          if (taller.modalidad != 2) {
            bCumple = false;
          }
        } else {
          if (taller.modalidad != 3) {
            bCumple = false;
          }
        }
      }

      // Filtro por estado
      if (this.selectEstado.value) {
        if (this.selectEstado.value == 1) {
          if (!taller.vigente) {
            bCumple = false;
          }
        } else {
          if (taller.vigente) {
            bCumple = false;
          }
        }
      }

      // Se agrega si es que paso los Filtros
      if (bCumple) {
        this.misTalleresFiltrado.push(taller);
      }
    });
    this.$q.loading.hide();
  }

  private buscardorTalleres() {
    if (this.$router.currentRoute.name == "Talleres") {
      this.$router.go(0);
    } else {
      this.$router.push({ name: "Talleres" }).catch(err => {});
    }
  }

  private irLink(link: any) {
    window.open(link, "_blank");
  }

  private verTaller(nombre_web: any, idTaller_horario: any) {
    if (
      this.$router.currentRoute.name == "PerfilHorario" &&
      this.$router.currentRoute.params.name == nombre_web &&
      this.$router.currentRoute.params.id == idTaller_horario
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "PerfilHorario",
          params: { nombre: nombre_web, id: idTaller_horario }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };
  private getPaginationLabel(
    firstRowIndex: number,
    endRowIndex: number,
    totalRowsNumber: number
  ) {
    return firstRowIndex + " - " + endRowIndex + " de " + totalRowsNumber;
  }

  private getTipo(item) {
    if (item.modalidad == 0) {
      return "Taller";
    } else if (item.modalidad == 1) {
      return "Actividades";
    } else if (item.modalidad == 2) {
      return "Servicios";
    } else {
      return "Actividad masiva";
    }
  }
  private getEstado(item) {
    if (item.estado == 1) {
      return "Inscrito";
    } else if (item.estado == 2) {
      return "Anulada";
    } else if (item.estado == 3) {
      return "Pendiente";
    } else if (item.estado == 4) {
      return "Rechazado";
    } else if (item.estado == 100) {
      return "Familiar inscrito";
    } else {
      return "Retirado";
    }
  }
  private getEstadoFamiliar(item) {
    if (item.estado == 1) {
      return "Inscrito";
    } else if (item.estado == 2) {
      return "Anulada";
    } else if (item.estado == 3) {
      return "Pendiente";
    } else if (item.estado == 4) {
      return "Rechazado";
    } else {
      return "Retirado";
    }
  }
  private verFamiliares(data) {
    this.dataFamiliares = [];
    for (let i = 0; i < data.familiares.length; i++) {
      this.dataFamiliares.push(data.familiares[i]);
      this.dataFamiliares[i]["nombre_taller"] = data.nombre_taller;
      this.dataFamiliares[i]["taller"] = data.nombre_taller;
      this.dataFamiliares[i]["horario"] = data.horario;
      this.dataFamiliares[i]["modalidad"] = data.modalidad;
      this.dataFamiliares[i]["idTaller_horario"] = data.idTaller_horario;
    }
    this.showFamiliares = true;
  }
  private printImage(data) {
    const qr = new Image();
    qr.src = `data: ${data.qr}`;
    this.canvas = document.getElementById("myCanvas") as any;

    this.img.src = require("@/assets/img/banner_pdf.png");
    // this.img.crossOrigin = "anonymous";
    this.img.onload = () => {
      if (this.canvas) {
        let addY = 80;
        let imgHeight = 260;
        this.canvas.width = 1157;
        this.canvas.height = 1637;
        const ctx = this.canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(this.img, 0, 0, 1157, imgHeight);
        ctx.textAlign = "start";
        // To change the color on the rectangle, just manipulate the context
        ctx.strokeStyle = "#5F6A6A";
        ctx.fillStyle = "white";
        // this.roundRect(ctx, 49, 300 + addY, 1060, 400, 20, true, true);

        ctx.drawImage(qr, 740, 450 + addY, 300, 300);
        ctx.fillStyle = "#5F6A6A";
        ctx.textAlign = "center";
        ctx.font = "bold 23px Verdana";
        ctx.fillText(data.ticket, 890, 770 + addY);
        ctx.textAlign = "center";
        ctx.fillStyle = "#000000";
        ctx.font = "bold 25px Verdana";
        ctx.fillText(
          "Comprobante de Reserva",
          // this.reservaUsuario.nombre_evento,
          this.canvas.width / 2 - 5,
          270 + addY
        );

        ctx.textAlign = "start";

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Reserva para: ", 120, 375 + addY);
        ctx.fillStyle = "#000000";
        let subStr;
        let sub2;
        if (data.taller.length > 50) {
          subStr = data.taller.substring(0, 79);
          sub2 = data.taller.substring(79);
        } else {
          subStr = data.taller;
        }
        ctx.fillText(subStr, 120, 405 + addY);
        if (sub2) {
          ctx.fillText(sub2, 120, 430 + addY);
        }

        ctx.fillStyle = "#02436b";
        ctx.fillText("Horario: ", 120, 455 + addY);
        ctx.fillStyle = "#000000";
        let subStrhorario;
        let subhorario2;
        if (data.horario.length > 50) {
          subStrhorario = data.horario.substring(0, 50);
          subhorario2 = data.horario.substring(50);
        } else {
          subStrhorario = data.horario;
        }
        ctx.fillText(subStrhorario, 120, 485 + addY);
        if (subhorario2) {
          ctx.fillText(subhorario2, 120, 505 + addY);
        }

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Nombre: ", 120, 535 + addY);
        ctx.fillStyle = "#000000";
        ctx.fillText(data.nombre, 120, 565 + addY);

        ctx.fillStyle = "#02436b";
        ctx.fillText("Rut: ", 120, 615 + addY);
        ctx.fillStyle = "#000000";
        ctx.fillText(data.rut, 120, 645 + addY);

        ctx.textAlign = "center";
        ctx.fillStyle = "#000000";
        ctx.fillText(
          "Recuerda presentar tu identificación el día de la reserva.",
          this.canvas.width / 2 - 5,
          // 980
          850 + addY
        );
        ctx.fillText("¡Te Esperamos!", this.canvas.width / 2 - 5, 890 + addY);

        let width = this.canvas.width;
        let height = this.canvas.height;
        var pdf = new jsPDF("p", "pt", [595.28, 841.89]);

        //then we get the dimensions from the 'pdf' file itself
        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();
        pdf.addImage(
          this.canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          width,
          height,
          undefined,
          "FAST"
        );
        pdf.save("Reserva.pdf");
      }
    };
  }
}
