
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface Tipos {
  value: number | any;
  label: string;
}

@Component
export default class EditarPerfilComponent extends Mixins(FormValidator) {
  private selectTipo: Array<any> = [];
  private newdate = "";
  private userData = {
    id: this.user.id,
    name: this.user.name,
    fecha_nacimiento: this.user.fecha_nacimiento,
    sexo: this.user.sexo,
    phone: this.user.phone,
    email: this.user.email,
    calle: this.user.calle,
    id_calle: this.user.id_calle,
    numero: this.user.numero,
    aclaratoria: this.user.aclaratoria,
    comuna: this.user.comuna,
    id_comuna: this.user.id_comuna
  };

  private streetsListOptions: Array<any> = [];
  private streetsList: any[] = [];

  private comunasListOptions: Array<any> = [];
  private comunasList: any[] = [];

  private datosTipo: Tipos = {
    value: null,
    label: "Género"
  };

  private dataCalle: Tipos = {
    value: null,
    label: ""
  };

  private dataComuna: Tipos = {
    value: null,
    label: ""
  };
  private edad = 0;

  private formattedFecha = "";

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("datosTipo")
  private sexoTipo(newValue: any) {
    this.userData.sexo = newValue.value;
  }

  @Watch("dataCalle")
  private calleWatcher(newValue: any) {
    this.userData.calle = newValue.label;
    this.userData.id_calle = newValue.value;
  }

  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.userData.comuna = newValue.label;
    this.userData.id_comuna = newValue.value;
  }
  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.userData.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.userData.fecha_nacimiento = new Date(birthDate);
    }
  }

  private mounted() {
    if (this.$store.state.user && this.$store.state.user) {
      this.userData = {
        id: this.$store.state.user.id,
        name: this.$store.state.user.username,
        fecha_nacimiento: this.$store.state.user.fecha_nacimiento,
        sexo: this.$store.state.user.sexo,
        phone: this.$store.state.user.phone,
        email: this.$store.state.user.email,
        calle: this.$store.state.user.calle,
        id_calle: this.$store.state.user.id_calle,
        numero: this.$store.state.user.numero,
        aclaratoria: this.$store.state.user.aclaratoria,
        comuna: this.$store.state.user.comuna,
        id_comuna: this.$store.state.user.id_comuna
      };
    }

    this.selectTipo.push(
      { value: 0, label: this.$t("fields.sex-female").toString() },
      { value: 1, label: this.$t("fields.sex-male").toString() },
      { value: 2, label: this.$t("fields.sex-other").toString() },
      { value: 3, label: "Prefiero no contestar" }
    );

    for (let i = 0; i < this.selectTipo.length; i++) {
      if (this.selectTipo[i].value == this.userData.sexo) {
        this.datosTipo = this.selectTipo[i];
        break;
      }
    }

    if (this.userData.fecha_nacimiento != null) {
      let formatFechaApi =
        this.userData.fecha_nacimiento.substring(8, 10) +
        "-" +
        this.userData.fecha_nacimiento.substring(5, 7) +
        "-" +
        this.userData.fecha_nacimiento.substring(0, 4);
      this.formattedFecha = formatFechaApi;
    }
    this.getComunas();
  }

  get user() {
    return this.$store.state.user;
  }

  private getCalles() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("calles")
      .then(res => {
        this.$q.loading.hide();
        res.data.forEach((street: any) => {
          this.streetsList.push(street);
        });
        if (this.userData.id_calle != -1) {
          for (let i = 0; i < this.streetsList.length; i++) {
            if (this.streetsList[i].value == this.userData.id_calle) {
              this.dataCalle = this.streetsList[i];
              break;
            }
          }
        } else {
          this.setModel(this.userData.calle);
        }
      })
      .catch(error => {
        this.$q.loading.hide();
      });
  }

  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("comunas")
      .then(res => {
        this.$q.loading.hide();
        res.data.forEach((comuna: any) => {
          this.comunasList.push(comuna);
        });
        if (this.userData.id_comuna != -1) {
          for (let i = 0; i < this.comunasList.length; i++) {
            if (this.comunasList[i].value == this.userData.id_comuna) {
              this.dataComuna = this.comunasList[i];
              break;
            }
          }
        }
      })
      .catch(error => {
        this.$q.loading.hide();
      });
  }
  private updateData() {
    if (this.userData.name == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.NO_NAME").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.checkFechaMayorAHoy(this.userData.fecha_nacimiento)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: "La fecha de nacimiento no puede ser mayor a la actual.",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (
      this.userData.fecha_nacimiento == null ||
      !this.checkFecha(this.userData.fecha_nacimiento)
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.userData.fecha_nacimiento.getFullYear() < 1890) {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
          return;
        }
      }
    }

    if (this.userData.email == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.validEmail(this.userData.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("messages.invalid_email").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.userData.phone || this.userData.phone == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.userData.phone.length <= 8) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.userData.calle.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.NO_CALLE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.userData.numero.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.data_update").toString(),
        text: this.$t("notifications.error.NO_NUMERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.userData.comuna || this.userData.comuna.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Por favor, seleccione una comuna.",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    this.$axios
      .put("user/edit", {
        nombre: this.userData.name,
        fecha_nacimiento: this.userData.fecha_nacimiento,
        sexo: this.userData.sexo,
        telefono: this.userData.phone,
        email: this.userData.email,
        calle: this.userData.calle,
        numero: this.userData.numero,
        aclaratoria: this.userData.aclaratoria,
        comuna: this.userData.comuna
      })
      .then(res => {
        this.$q.loading.hide();
        var month = this.userData.fecha_nacimiento.getMonth() + 1; //months from 1-12
        var day = this.userData.fecha_nacimiento.getDate();
        var year = this.userData.fecha_nacimiento.getFullYear();

        this.newdate = new Date(year + "-" + month + "-" + day).toISOString();
        const data = {
          id: this.userData.id,
          username: this.userData.name,
          fecha_nacimiento: this.newdate,
          sexo: this.userData.sexo,
          email: this.userData.email,
          telefono: this.userData.phone,
          calle: this.userData.calle,
          numero: this.userData.numero,
          id_calle: this.userData.id_calle,
          aclaratoria: this.userData.aclaratoria,
          comuna: this.userData.comuna,
          id_comuna: this.userData.id_comuna
        };
        this.$store.dispatch("GET_USER", data);
        this.$swal({
          icon: "success",
          title: this.$t("notifications.success.title").toString(),
          text: this.$t("notifications.success.UPDATE_DATA").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$router.push({ name: "Talleres" });
      })
      .catch(error => {
        this.$q.loading.hide();
        const message = error.response.data.message;
        if (message === "REGISTERED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_NAME") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_NAME").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "telefono invalido") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.data_update").toString(),
            text: this.$t("notifications.error.UPDATE_DATA").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
      });
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private formatRut(rut: string) {
    rut = rut.replace(/\./g, "").replace(/-/g, "");
    const rutNumber = rut
      .substring(0, rut.length - 1)
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    const dv = rut.substring(rut.length - 1);
    return `${rutNumber}-${dv}`.toUpperCase();
  }
  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  private filterFn(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.streetsListOptions = this.streetsList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }
  private filterFnc(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunasListOptions = this.comunasList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private setModel(val) {
    let datos = {
      value: 0,
      label: val
    };
    for (let i = 0; i < this.streetsList.length; i++) {
      if (this.streetsList[i].label == val) {
        datos = {
          value: this.streetsList[i].value,
          label: this.streetsList[i].label
        };
      }
    }
    this.dataCalle = datos;
  }

  private checkFechaMayorAHoy(d) {
    let hoy = new Date();
    if (d > hoy) {
      return true;
    } else {
      return false;
    }
  }
}
