
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import jsPDF from "jspdf";

interface Form {
  rut: string | null;
}

interface infoTallerHorario {
  clases: number | any;
  documentos: Array<any>;
  horario: string | any;
  link: string | any;
  mensajes: Array<any>;
  nombre_taller: string | any;
  objetivo: string | any;
  profesor: string | any;
  user_asistencia: number | any;
  foto: string | any;
  qr: string | any;
  rut: string | any;
  nombre: string | any;
  ticket: string | any;
  tipo_taller: number | any;
  direccion: string | any;
  estado_inscripcion: any;
}

@Component({
  components: {
    FormValidator
  }
})
export default class PerfilHorario extends Mixins(FormValidator) {
  private nombreWeb = "";
  private id_taller_horario = "";
  private ticket: null | any = "";
  private nombreActividad = [];
  private dataTallerHorario: infoTallerHorario = {
    clases: 0,
    documentos: [],
    horario: "",
    link: "",
    mensajes: [],
    nombre_taller: "",
    objetivo: "",
    profesor: "",
    user_asistencia: 0,
    foto: "",
    qr: "",
    rut: "",
    nombre: "",
    ticket: "",
    tipo_taller: null,
    direccion: "",
    estado_inscripcion: ""
  };
  private value = 0;

  private thumbStyle = {
    right: "4px",
    borderRadius: "5px",
    backgroundColor: "#575CA4",
    width: "5px",
    opacity: 0.75
  };

  private barStyle = {
    right: "2px",
    borderRadius: "9px",
    backgroundColor: "#575CA4",
    width: "9px",
    opacity: 0.2
  };

  private canvas: any = null;
  private cardImage = new Image();

  private mensajes = [];

  private codReserva = "000000001";
  private img = new Image();

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != "" &&
      this.$router.currentRoute.params.id &&
      this.$router.currentRoute.params.id != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.id_taller_horario = this.$router.currentRoute.params.id;
      this.$q.loading.show({
        delay: 100 // ms
      });
      this.$axios
        .get(
          "perfil/taller/horario?nombre_web=" +
            this.nombreWeb +
            "&id_taller_horario=" +
            this.id_taller_horario
        )
        .then((res: any) => {
          this.dataTallerHorario = res.data;
          if (this.dataTallerHorario.foto) {
            this.dataTallerHorario.foto =
              "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
              this.dataTallerHorario.foto;
          } else {
            this.dataTallerHorario.foto = require("@/assets/img/default.png");
          }
          if (
            this.dataTallerHorario.user_asistencia == 0 &&
            this.dataTallerHorario.clases == 0
          ) {
            this.value = 0;
          } else {
            this.value =
              (this.dataTallerHorario.user_asistencia * 100) /
              this.dataTallerHorario.clases;
          }

          const qr = new Image();
          if (this.dataTallerHorario.qr) {
            qr.src = `data: ${this.dataTallerHorario.qr}`;
            this.dataTallerHorario.qr = qr;
            this.cardImage.src = require("@/assets/logos/ticket.png");
          }

          if (
            this.dataTallerHorario.documentos &&
            this.dataTallerHorario.documentos.length > 0
          ) {
            for (let i = 0; i < this.dataTallerHorario.documentos.length; i++) {
              if (this.dataTallerHorario.documentos[i].tipo == 1) {
                this.dataTallerHorario.documentos[i].link =
                  "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
                  this.dataTallerHorario.documentos[i].link;
              }
            }
          }
          this.$q.loading.hide();
        })
        .catch((err: any) => {
          this.$q.loading.hide();
          console.log(err);
        })
        .finally(() => {
          this.$q.loading.hide();
          if (this.dataTallerHorario.qr) {
            this.canvas = document.getElementById("myCanvas") as any;
            // this.printImage();
          }
        });
      // this.getTallerHorario();
      this.revisarMensaje();
    }
  }

  // private getTallerHorario() {

  //   this.$axios
  //     .get(
  //       "perfil/taller/horario?nombre_web=" +
  //         this.nombreWeb +
  //         "&id_taller_horario=" +
  //         this.id_taller_horario
  //     )
  //     .then((res: any) => {
  //       this.dataTallerHorario = res.data;
  //       this.dataTallerHorario.foto =
  //         "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
  //         this.dataTallerHorario.foto;

  //       if (
  //         this.dataTallerHorario.user_asistencia == 0 &&
  //         this.dataTallerHorario.clases == 0
  //       ) {
  //         this.value = 0;
  //       } else {
  //         this.value =
  //           (this.dataTallerHorario.user_asistencia * 100) /
  //           this.dataTallerHorario.clases;
  //       }

  //       const qr = new Image();
  //       if (this.dataTallerHorario.qr) {
  //         qr.src = `data: ${this.dataTallerHorario.qr}`;
  //         this.dataTallerHorario.qr = qr;
  //         this.cardImage.src = require("@/assets/logos/ticket.png");
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       this.$q.loading.hide();
  //       if (this.dataTallerHorario.qr) {
  //         this.canvas = document.getElementById("myCanvas") as any;
  //         this.printImage();
  //       }
  //     });
  // }

  private revisarMensaje() {
    this.$axios
      .post("revision/mensajes", {
        id_taller_horario: this.id_taller_horario
      })
      .then((res: any) => {})

      .catch((error: any) => {});
  }

  private printImage() {
    this.canvas = document.getElementById("myCanvas") as any;

    this.img.src = require("@/assets/img/banner_pdf.png");
    // this.img.crossOrigin = "anonymous";
    this.img.onload = () => {
      if (this.canvas) {
        let addY = 80;
        let imgHeight = 260;
        this.canvas.width = 1157;
        this.canvas.height = 1637;
        const ctx = this.canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(this.img, 0, 0, 1157, imgHeight);
        ctx.textAlign = "start";
        // To change the color on the rectangle, just manipulate the context
        ctx.strokeStyle = "#5F6A6A";
        ctx.fillStyle = "white";
        // this.roundRect(ctx, 49, 300 + addY, 1060, 400, 20, true, true);

        ctx.drawImage(this.dataTallerHorario.qr, 740, 450 + addY, 300, 300);
        ctx.fillStyle = "#5F6A6A";
        ctx.textAlign = "center";
        ctx.font = "bold 18px Verdana";
        ctx.fillText(this.dataTallerHorario.ticket, 890, 770 + addY);

        ctx.textAlign = "center";
        ctx.fillStyle = "#000000";
        ctx.font = "bold 25px Verdana";
        ctx.fillText(
          "Comprobante de Reserva",
          // this.reservaUsuario.nombre_evento,
          this.canvas.width / 2 - 5,
          270 + addY
        );

        ctx.textAlign = "start";

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Reserva para: ", 120, 375 + addY);
        ctx.fillStyle = "#000000";
        let subStr;
        let sub2;
        if (this.dataTallerHorario.nombre_taller.length > 50) {
          subStr = this.dataTallerHorario.nombre_taller.substring(0, 79);
          sub2 = this.dataTallerHorario.nombre_taller.substring(79);
        } else {
          subStr = this.dataTallerHorario.nombre_taller;
        }
        ctx.fillText(subStr, 120, 405 + addY);
        if (sub2) {
          ctx.fillText(sub2, 120, 430 + addY);
        }

        ctx.fillStyle = "#02436b";
        ctx.fillText("Horario: ", 120, 455 + addY);
        ctx.fillStyle = "#000000";
        let subStrhorario;
        let subhorario2;
        if (this.dataTallerHorario.horario.length > 50) {
          subStrhorario = this.dataTallerHorario.horario.substring(0, 50);
          subhorario2 = this.dataTallerHorario.horario.substring(50);
        } else {
          subStrhorario = this.dataTallerHorario.horario;
        }
        ctx.fillText(subStrhorario, 120, 485 + addY);
        if (subhorario2) {
          ctx.fillText(subhorario2, 120, 505 + addY);
        }

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Nombre: ", 120, 535 + addY);
        ctx.fillStyle = "#000000";
        ctx.fillText(this.dataTallerHorario.nombre, 120, 565 + addY);

        ctx.fillStyle = "#02436b";
        ctx.fillText("Rut: ", 120, 615 + addY);
        ctx.fillStyle = "#000000";
        ctx.fillText(this.dataTallerHorario.rut, 120, 645 + addY);

        ctx.textAlign = "center";
        ctx.fillStyle = "#000000";
        ctx.fillText(
          "Recuerda presentar tu identificación el día de la reserva.",
          this.canvas.width / 2 - 5,
          // 980
          850 + addY
        );
        ctx.fillText("¡Te Esperamos!", this.canvas.width / 2 - 5, 890 + addY);

        let width = this.canvas.width;
        let height = this.canvas.height;
        var pdf = new jsPDF("p", "pt", [595.28, 841.89]);

        //then we get the dimensions from the 'pdf' file itself
        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();
        pdf.addImage(
          this.canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          width,
          height,
          undefined,
          "FAST"
        );
        pdf.save("Reserva.pdf");
      }
    };
  }

  private roundRect(
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill: any,
    stroke: any = true
  ) {
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  private pdf() {
    var doc = new jsPDF("p", "pt", "a2", true);

    doc.addImage(this.ticket, "JPEG", 60, 80, 0, 0);
    doc.save("ticket.pdf");
  }

  private irLink(link: any) {
    window.open(link, "_blank");
  }

  private linkDoc(link: any) {
    window.open(link, "_blank");
  }

  private descargarDoc(link: any) {
    window.open(link, "_blank");
  }

  private formatDate(date: String) {
    // 2019-09-25T19:04:57.000Z to 25-09-2020 19:04
    var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(8, 10),
      hours = date.substring(11, 13),
      minutes = date.substring(14, 16);
    return [day, month, year].join("-") + " " + hours + ":" + minutes;
  }
}
