import { render, staticRenderFns } from "./PerfilTaller.vue?vue&type=template&id=ec77d546&scoped=true&"
import script from "./PerfilTaller.vue?vue&type=script&lang=ts&"
export * from "./PerfilTaller.vue?vue&type=script&lang=ts&"
import style0 from "./PerfilTaller.vue?vue&type=style&index=0&id=ec77d546&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec77d546",
  null
  
)

export default component.exports