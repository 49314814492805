
import { Vue, Component, Prop } from "vue-property-decorator";
import Talleres from "@/components/Talleres/TalleresComponent.vue";

@Component({
  components: {
    Talleres
  }
})
export default class TalleresView extends Vue {
  private mounted() {}
}
