
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { ref } from "vue";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
interface infoHorario {
  id: string | any;
  horario_label: string | any;
  dia: string | any;
  horaInicio: string | any;
  horaTermino: string | any;
  profesor: string | any;
  formato: string | any;
  cupos: number | any;
  lugar: string | any;
  fechaExpiracion: string | any;
  horario: string | any;
  modalidadPago: any | null;
  infomodalidadpago: string | null;
}
interface infoTaller {
  id: number | null;
  id_categoria: number | null;
  fecha_inicio: string | null;
  fecha_termino: string | null;
  formato: number | null;
  tipo_genero: number | null;
  tipo_vecino: number | null;
  tipo_web: number | null;
  edad_min: number | null;
  edad_max: number | null;
  nombre: string | null;
  descripcion_larga: string | null;
  objetivo: string | null;
  gratuito: number | null;
  valor_normal: number | null;
  valor_descuento: number | null;
  matricula_normal: number | null;
  matricula_descuento: number | null;
  modalidad: number | null;
  cuotas: number | null;
  foto: string | null;
  estado: number | null;
  horario: any | null;
  tipo_inscripcion: number | null;
}

interface infoUser {
  nombre: string | any;
  rut: string | any;
  apellido_paterno: string | any;
  apellido_materno: string | any;
  id_taller_horario: number | any;
  fecha: string | any;
  estado: number | any;
  tipo_vecino: number | any;
  email: string | any;
  telefono: string | any;
}

interface respuesta {
  id_pregunta: number | null;
  nombre: string | null;
  obligatorio: number | null;
  tipo: number | null;
  model: any | null;
}

interface SeccionRespuesta {
  id_seccion: number | null;
  preguntas: Array<respuesta>;
}

interface SignupData {
  name: string;
  sexo: number | null;
  fecha_nacimiento: Date | null;
  rut: string;
  email: string;
  phone: number | null;
  paterno: string;
  materno: string;
  calle: string;
  comuna: string;
  numero: string;
  aclaratoria: string | null;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    FormValidator
  }
})
export default class PerfilTaller extends Mixins(FormValidator) {
  private model: Array<any> = [];
  private documentosRespuestas: Array<any> = [];
  private formulario: any = null;
  private step: number = 1;
  private idHorarioSeclected: any = "";
  private respuestas: Array<SeccionRespuesta> = [];
  private dataPreguntas: Array<respuesta> = [];
  private win = window;
  private verHorarios = false;
  private horario = false;
  private confirmarDialog = false;
  private selectModalidadPago = false;
  private optionsModalidad: Array<any> = [];
  private inscribeFamiliar = false;
  private btnselectdisabled = true;
  private showFormulario = false;
  private dialogPhone = false;
  private loading = false;
  private tallerDisponible = false;
  private logged: boolean = false;
  private bloques = [];
  private cantidad = 0;
  private cupoTotal = 0;
  private cupoTotalIlimitado = 0;
  private ilimitado = 0;
  private nombreWeb = "";
  private idTaller = "";
  private fechaHoy = "";
  private tipo_inscripcion = "";
  private respuestas_doc: number[] = [];
  private lugarTaller: Array<any> = [];
  private respuestasApi: Array<any> = [];
  private comunasListOptions: Array<any> = [];
  private comunasList: any[] = [];
  private dataTaller: infoTaller = {
    id: null,
    id_categoria: null,
    categoria: null,
    fecha_inicio: null,
    fecha_termino: null,
    formato: null,
    tipo_genero: null,
    tipo_vecino: null,
    tipo_web: 1,
    edad_min: null,
    edad_max: null,
    nombre: null,
    descripcion_larga: null,
    objetivo: null,
    gratuito: null,
    valor_normal: null,
    valor_descuento: null,
    matricula_normal: null,
    matricula_descuento: null,
    modalidad: null,
    cuotas: null,
    foto: null,
    estado: null,
    horario: null,
    tipo_inscripcion: null
  };
  private taller: infoHorario = {
    id: "",
    horario_label: "",
    dia: "",
    horaInicio: "",
    horaTermino: "",
    profesor: "",
    formato: "",
    cupos: 0,
    lugar: "",
    fechaExpiracion: "",
    horario: "",
    modalidadPago: null,
    infomodalidadpago: ""
  };

  private selectedModalidad: Tipos = {
    value: 0,
    label: "Seleccione una modalidad"
  };

  private dataComuna: Tipos = {
    value: 108,
    label: "La Reina"
  };

  private dataUser: infoUser = {
    nombre: "",
    rut: "",
    apellido_paterno: "",
    apellido_materno: "",
    id_taller_horario: null,
    fecha: "",
    estado: 1,
    tipo_vecino: 1,
    email: "",
    telefono: ""
  };

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  private showInscribirFamiliar = false;
  private nombreSelectedTaller = "";
  private formattedRut: string = "";
  private formattedFecha = "";
  private id_horario = "";
  private id_taller = "";
  private datosTipoGenero: Tipos = {
    value: null,
    label: "Género"
  };
  private selectTipoGenero: Array<any> = [
    { value: 1, label: "Masculino" },
    { value: 2, label: "Femenino" },
    { value: 3, label: "Otro" },
    { value: 4, label: "Prefiero no contestar" }
  ];
  private datosTipoIdentificacion: Tipos = {
    value: 1,
    label: "Cédula de identidad (Rut)"
  };
  private selectTipoIdentificacion: Array<any> = [
    { value: 1, label: "Cédula de identidad (Rut)" }
  ];
  private tipoDocumento = 1;
  protected formDataAdicional: SignupData = {
    name: "",
    paterno: "",
    materno: "",
    sexo: null as number | null,
    fecha_nacimiento: null as Date | null,
    rut: "",
    email: "",
    phone: null,
    calle: "",
    comuna: "",
    numero: "",
    aclaratoria: ""
  };
  private disabled = true;
  private is_inscribirFamiliar = false;
  @Watch("$store.state.user")
  private userWatch(newValue: any) {
    if (newValue && newValue.id && newValue.id != "") {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    this.formDataAdicional.rut = newValue;
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1).toUpperCase();
      this.formattedRut = `${rut}-${dv}`;
      if (newValue.length > 7) {
        if (this.validateRut(newValue)) {
          this.fillForm(newValue.substring(0, newValue.length - 1) + "-" + dv);
        }
      }
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.formDataAdicional.comuna = newValue.label;
  }

  @Watch("datosTipoGenero")
  private sexoTipo(newValue: any) {
    this.formDataAdicional.sexo = newValue.value;
  }
  @Watch("datosTipoIdentificacion")
  private identificacionTipo(newValue: any) {
    this.tipoDocumento = newValue.value;
    this.formDataAdicional.rut = "";
    this.formattedRut = "";
    this.disabled = false;
  }

  @Watch("selectedModalidad")
  private modalidadWatch(newValue: any) {
    if (newValue.value > 0) {
      this.btnselectdisabled = false;
    } else {
      this.btnselectdisabled = true;
    }
  }
  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    if (newValue != "") {
      const now = new Date(Date.now());
      if (typeof newValue == "object") {
        let day = newValue.getDate();
        let month = newValue.getMonth() + 1;
        let year = newValue.getFullYear();
        if (month < 10) {
          if (day < 10) {
            newValue = `0${day}/0${month}/${year}`;
          } else {
            newValue = `${day}/0${month}/${year}`;
          }
        } else {
          if (day < 10) {
            newValue = `0${day}/${month}/${year}`;
          } else {
            newValue = `${day}/${month}/${year}`;
          }
        }
      }
      var birthDate: any = "";
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
          birthDate = (newValue.substring(6, 10) +
            "-" +
            newValue.substring(3, 5) +
            "-" +
            newValue.substring(0, 2) +
            " 12:00:00") as any;
        } else {
          birthDate = (newValue.substring(6, 10) +
            "-" +
            newValue.substring(3, 5) +
            "-" +
            newValue.substring(0, 2) +
            "T12:00:00") as any;
        }
      } else if (ua.indexOf("mozilla") != -1) {
        //Usa Mozilla
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
      if (birthDate && birthDate.length <= 18) {
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.formDataAdicional.fecha_nacimiento = null;
        return;
      }
      let diff;
      if (birthDate instanceof Date) {
        diff = now.getTime() - birthDate.getTime();
        this.formDataAdicional.fecha_nacimiento = birthDate;
      } else {
        diff = now.getTime() - new Date(birthDate).getTime();
        this.formDataAdicional.fecha_nacimiento = new Date(birthDate);
      }
    }
  }
  private mounted() {
    if (
      this.$router.currentRoute.params.id &&
      this.$router.currentRoute.params.id != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.idTaller = this.$router.currentRoute.params.id;
      this.getPerfilTaller();
    }
    if (
      this.$store.state.user &&
      this.$store.state.user.id &&
      this.$store.state.user.id != ""
    ) {
      this.logged = true;
      this.dataUser.nombre = this.$store.state.user.name;
      this.dataUser.rut = this.$store.state.user.rut;
      this.dataUser.apellido_paterno = this.$store.state.user.last_name;
      this.dataUser.apellido_materno = this.$store.state.user.second_name;
      this.dataUser.email = this.$store.state.user.email;
      this.dataUser.telefono = this.$store.state.user.phone;

      this.dataComuna.value = this.$store.state.user.id_comuna;
      this.dataComuna.label = this.$store.state.user.comuna;
    } else {
      this.logged = false;
    }

    this.getComunas();
    this.formDataAdicional.comuna = "La Reina";

    // const hoy = new Date();
    // this.fechaHoy = this.formatDate(hoy.toISOString());
    // this.fechaHoy = this.formatFecha2(this.fechaHoy);
  }

  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("comunas")
      .then(res => {
        this.$q.loading.hide();
        res.data.forEach((comuna: any) => {
          this.comunasList.push(comuna);
        });
        this.loading = false;
      })
      .catch(error => {
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private getPerfilTaller() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("perfil/taller?id=" + this.idTaller)
      .then((res: any) => {
        this.dataTaller = res.data;
        if (this.dataTaller.foto) {
          this.dataTaller.foto =
            "https://s3-us-west-2.amazonaws.com/s3.documentos.tarjetavecino.lareina/" +
            this.dataTaller.foto;
        } else {
          this.dataTaller.foto = require("@/assets/img/default.png");
        }
        if (this.dataTaller.horario) {
          this.tallerDisponible = false;
          for (let i = 0; i < this.dataTaller.horario.length; i++) {
            if (this.dataTaller.horario[i].ilimitado == 1) {
              this.tallerDisponible = true;
            } else {
              if (
                this.dataTaller.horario[i].inscripciones <
                this.dataTaller.horario[i].cupo
              ) {
                this.tallerDisponible = true;
              }
            }
            if (this.dataTaller.horario[i].lugar.includes("ONLINE")) {
              this.dataTaller.horario[i].lugar = "ONLINE";
            }
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.error.ERROR_FORM").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        if (this.$router.currentRoute.name == "Talleres") {
          this.$router.go(0);
        } else {
          this.$router.push({ name: "Talleres" }).catch(err => {});
        }
      });
  }

  private verHorariosDos(item: any) {
    this.idHorarioSeclected = item;
    for (let i = 0; i < this.dataTaller.horario.length; i++) {
      if (this.dataTaller.horario[i].id == item) {
        this.horario = true;
        this.bloques = this.dataTaller.horario[i].bloques;
        this.taller.id = this.dataTaller.horario[i].id;
        this.taller.horario_label = this.dataTaller.horario[i].horario;
        this.taller.profesor = this.dataTaller.horario[i].profesor;
        this.taller.formato = this.formato(this.dataTaller.formato);
        this.taller.cupos = this.dataTaller.horario[i].cupo;
        this.taller.lugar = this.dataTaller.horario[i].lugar;
        this.ilimitado = this.dataTaller.horario[i].ilimitado;
        this.cupoTotalIlimitado = this.dataTaller.horario[i].cupo;
        if (this.dataTaller.horario[i].inscripciones) {
          this.cantidad = this.dataTaller.horario[i].inscripciones;
          this.cupoTotal = this.dataTaller.horario[i].cupo - this.cantidad;
        } else {
          this.cupoTotal = this.taller.cupos;
        }
        this.taller.fechaExpiracion = this.dataTaller.horario[
          i
        ].fechaExpiracion;
        this.taller.horario = this.dataTaller.horario[i].horario;
        this.taller.infomodalidadpago = this.dataTaller.horario[i].infomodalidadpago;
        this.taller.modalidadPago = this.dataTaller.horario[i].modalidadPago;
      }
    }
    this.dataUser.id_taller_horario = item;
  }

  private horaFormat(dataHora: any) {
    const hora = dataHora.substring(11, 16);
    if (hora != "") {
      return hora;
    }
  }

  private formatFecha(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[2] + "-" + newDate[1] + "-" + newDate[0];
  }

  private formatFecha2(fecha: any) {
    let date = fecha.split(" ", 2);
    let newDate = date[0].split("-");
    return newDate[0] + "-" + newDate[1] + "-" + newDate[2];
  }

  private formato(dataFormato: any) {
    let dato: any = "";
    if (dataFormato == 0) {
      dato = this.$t("fields.on-site");
    } else if (dataFormato == 1) {
      dato = this.$t("fields.online");
    } else if (dataFormato == 2) {
      dato = this.$t("fields.mixed");
    }
    return dato;
  }

  private diaFormat(dataDia: any) {
    let dia: any = "";
    if (dataDia == 1) {
      dia = this.$t("fields.monday");
    } else if (dataDia == 2) {
      dia = this.$t("fields.tuesday");
    } else if (dataDia == 3) {
      dia = this.$t("fields.wednesday");
    } else if (dataDia == 4) {
      dia = this.$t("fields.thursday");
    } else if (dataDia == 5) {
      dia = this.$t("fields.friday");
    } else if (dataDia == 6) {
      dia = this.$t("fields.saturday");
    } else if (dataDia == 7) {
      dia = this.$t("fields.sunday");
    }

    if (dia != "") {
      return dia;
    }
  }
  private getAgeText() {
    let label = "";
    if (this.dataTaller.edad_min && this.dataTaller.edad_min != 5) {
      label = this.$t("messages.min_age", {
        edad: this.dataTaller.edad_min
      }).toString();
    }
    if (this.dataTaller.edad_max && this.dataTaller.edad_max != 120) {
      if (label != "") {
        label = label + " - ";
      }
      label =
        label +
        this.$t("messages.max_age", {
          edad: this.dataTaller.edad_max
        }).toString();
    }
    if (label == "") {
      label = this.$t("messages.no_age").toString();
    }
    return label;
  }
  private onTransition() {
    var elmnt = document.getElementById("stepperEncuesta");
    (elmnt as any).scrollIntoView();
  }

  private getStep(index) {
    return index + 1;
  }
  private continueRegister() {
    this.horario = false;
    this.confirmarDialog = true;
    this.is_inscribirFamiliar = false;
    this.selectModalidadPago = false;
  }
  private continueRegisterModalidad(options, inscribeF) {
    this.selectedModalidad = {
      value: 0,
      label: "Seleccione una modalidad"
    };
    this.horario = false;
    this.confirmarDialog = false;
    this.is_inscribirFamiliar = false;
    this.selectModalidadPago = true;
    this.optionsModalidad = options;
    this.inscribeFamiliar = inscribeF;
  }

  private formatFechaLabel(fecha_inicio, fecha_termino) {
    if (fecha_inicio && fecha_termino) {
      var anho_inicio = fecha_inicio.substring(0, 4);
      var mes_inicio: any = parseInt(fecha_inicio.substring(5, 7), 10);
      var dia_inicio = fecha_inicio.substring(8, 10);
      var hora_inicio = fecha_inicio.substring(10, 16);

      var anho_termino = fecha_termino.substring(0, 4);
      var mes_termino: any = parseInt(fecha_termino.substring(5, 7), 10);
      var dia_termino = fecha_termino.substring(8, 10);
      var hora_termino = fecha_termino.substring(10, 16);

      if (mes_inicio == 1) {
        mes_inicio = this.$t("calendar.month.january").toString();
      } else if (mes_inicio == 2) {
        mes_inicio = this.$t("calendar.month.february").toString();
      } else if (mes_inicio == 3) {
        mes_inicio = this.$t("calendar.month.march").toString();
      } else if (mes_inicio == 4) {
        mes_inicio = this.$t("calendar.month.april").toString();
      } else if (mes_inicio == 5) {
        mes_inicio = this.$t("calendar.month.may").toString();
      } else if (mes_inicio == 6) {
        mes_inicio = this.$t("calendar.month.june").toString();
      } else if (mes_inicio == 7) {
        mes_inicio = this.$t("calendar.month.july").toString();
      } else if (mes_inicio == 8) {
        mes_inicio = this.$t("calendar.month.august").toString();
      } else if (mes_inicio == 9) {
        mes_inicio = this.$t("calendar.month.september").toString();
      } else if (mes_inicio == 10) {
        mes_inicio = this.$t("calendar.month.october").toString();
      } else if (mes_inicio == 11) {
        mes_inicio = this.$t("calendar.month.november").toString();
      } else {
        mes_inicio = this.$t("calendar.month.december").toString();
      }
      if (mes_termino == 1) {
        mes_termino = this.$t("calendar.month.january").toString();
      } else if (mes_termino == 2) {
        mes_termino = this.$t("calendar.month.february").toString();
      } else if (mes_termino == 3) {
        mes_termino = this.$t("calendar.month.march").toString();
      } else if (mes_termino == 4) {
        mes_termino = this.$t("calendar.month.april").toString();
      } else if (mes_termino == 5) {
        mes_termino = this.$t("calendar.month.may").toString();
      } else if (mes_termino == 6) {
        mes_termino = this.$t("calendar.month.june").toString();
      } else if (mes_termino == 7) {
        mes_termino = this.$t("calendar.month.july").toString();
      } else if (mes_termino == 8) {
        mes_termino = this.$t("calendar.month.august").toString();
      } else if (mes_termino == 9) {
        mes_termino = this.$t("calendar.month.september").toString();
      } else if (mes_termino == 10) {
        mes_termino = this.$t("calendar.month.october").toString();
      } else if (mes_termino == 11) {
        mes_termino = this.$t("calendar.month.november").toString();
      } else {
        mes_termino = this.$t("calendar.month.december").toString();
      }
      var response = "";
      if (mes_inicio == mes_termino) {
        if (dia_inicio == dia_termino) {
          if (this.dataTaller.modalidad == 1) {
            response =
              dia_inicio +
              " " +
              this.$t("calendar.extra.of") +
              " " +
              mes_termino +
              " de " +
              anho_termino +
              " " +
              hora_inicio;
          } else {
            response =
              dia_inicio +
              " " +
              this.$t("calendar.extra.of") +
              " " +
              mes_termino +
              " de " +
              anho_termino;
          }
        } else {
          response =
            this.$t("calendar.extra.from") +
            " " +
            dia_inicio +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_inicio +
            " de " +
            anho_inicio +
            " " +
            this.$t("calendar.extra.to") +
            " " +
            dia_termino +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_termino +
            " de " +
            anho_termino;
        }
      } else {
        if (this.dataTaller.modalidad == 1) {
          response =
            this.$t("calendar.extra.from") +
            " " +
            dia_inicio +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_inicio +
            " de " +
            anho_inicio +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_inicio +
            " de " +
            anho_inicio +
            " " +
            this.$t("calendar.extra.to") +
            " " +
            dia_termino +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_termino +
            " de " +
            anho_termino +
            " " +
            hora_inicio;
        } else {
          response =
            this.$t("calendar.extra.from") +
            " " +
            dia_inicio +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_inicio +
            " de " +
            anho_inicio +
            " " +
            this.$t("calendar.extra.to") +
            " " +
            dia_termino +
            " " +
            this.$t("calendar.extra.of") +
            " " +
            mes_termino +
            " de " +
            anho_termino;
        }
      }
      return response;
    }
    return "";
  }

  private inscribirUser() {
    this.confirmarDialog = false;
    this.$q.loading.show({
      delay: 100 // ms
    });
    let datafamiliar = {};
    if (this.is_inscribirFamiliar == true) {
      datafamiliar = this.formDataAdicional;
    }
    this.$axios
      .post("inscripcion/taller", {
        id_taller_horario: this.dataUser.id_taller_horario,
        inscripcion_familiar: datafamiliar,
        tipo_documento: this.tipoDocumento,
        logged: this.logged,
        modalidadpago: this.selectedModalidad.value
      })
      .then((res: any) => {
        this.$q.loading.hide();
        this.loading = false;
        this.$swal({
          icon: "success",
          title: "Completado",
          text: res.data.message,
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$q.loading.hide();
        if (this.$router.currentRoute.name == "MisTalleres") {
          this.$router.go(0);
        } else {
          this.$router.push({ name: "MisTalleres" }).catch(err => {});
        }
      })
      .catch((error: any) => {
        this.$q.loading.hide();
        this.horario = false;
        const message = error.response.data.message;
        const type = error.response.data.type;
        let text: string = "";
        if (message === "USUARIO_REGISTRADO") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.already_sign").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "COMPLETAR_DATOS") {
          this.$swal({
            icon: "warning",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.complete_data").toString(),
            html: "Por favor complete los datos faltantes. ",
            showCancelButton: true,
            confirmButtonColor: "#346a82",
            cancelButtonColor: "#d33",
            focusConfirm: false,
            confirmButtonText:
              '<a class="bg-principal-tres" style="color: #fff;"  href="/editar">Editar Datos!</a>'
          });
        } else if (message === "NO_CUPO") {
          this.$swal({
            icon: "error",
            title: "Registro",
            text: "No hay cupo para este horario.",
            confirmButtonText: this.$t("buttons.accept").toString()
          }).then(result => {
            if (result.value) {
              this.$router.go(0);
            }
          });
        } else if (message === "INVALID_EDAD") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.invalid_age").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_HOMBRE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.only_men").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_MUJER") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.only_women").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_OTRO") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: "El horario seleccionado es solo para Otros géneros",
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_NO_CONTESTAR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text:
              "El horario seleccionado es solo para género  Prefiero no contestar",
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_TIPO_WEB") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.only_in_person").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
      });
  }

  private formatFunction(value) {
    // Solo cambiamos el formateo cuando es moneda en español y el numero es >= 1.000 o menor a 10.000
    if (value >= 1000 && value < 10000) {
      let num = this.formatter.format(10000), // -> [pre]10[sep]000[sub]
        pre = num.substr(0, num.indexOf("10")),
        sep = num.substr(num.indexOf("10") + 2, 1),
        sub = num.substr(num.indexOf("000") + 3);
      num = value.toString();
      return pre + num.slice(0, 1) + sep + num.slice(1) + sub;
    }
    // Sino devolvemos el número formateado normalmente
    return this.formatter.format(value);
  }

  private formatter = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP"
  });

  private scrollTop() {
    // window.scrollTo(0, document.body.scrollHeight);
    if (this.$q.screen.xs || this.$q.screen.sm) {
      window.scroll({
        top: 2500,
        left: 0,
        behavior: "smooth"
      });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  private filterFnc(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunasListOptions = this.comunasList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {});
      }
    }
  }

  private formatDate(date: String) {
    // 2019-09-25T19:04:57.000Z to 25-09-2020 19:04
    var year = date.substring(0, 4),
      month = date.substring(5, 7),
      day = date.substring(8, 10),
      hours = date.substring(11, 13),
      minutes = date.substring(14, 16);
    return [day, month, year].join("-") + " " + hours + ":" + minutes;
  }

  private verLogin() {
    this.horario = false;
    this.$store.commit("SET_AUTHMODAL", true);
  }
  private getFormulario() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("taller/formulario?id=" + this.idTaller)
      .then((res: any) => {
        this.confirmarDialog = false;
        this.formulario = res.data.secciones;
        for (let i = 0; i < this.formulario.length; i++) {
          const seccion = this.formulario[i];
          this.dataPreguntas = [];
          for (let i = 0; i < seccion.preguntas.length; i++) {
            const pregunta = seccion.preguntas[i];
            let modelRespuesta: any = "";
            if (pregunta.tipo == 0) {
              modelRespuesta = [];
            } else if (pregunta.tipo == 1) {
              modelRespuesta = "";
            } else if (pregunta.tipo == 2) {
              modelRespuesta = "";
            } else if (pregunta.tipo == 3) {
              modelRespuesta = "";
            }
            this.dataPreguntas.push({
              id_pregunta: pregunta.id,
              nombre: pregunta.nombre,
              obligatorio: pregunta.obligatorio,
              tipo: pregunta.tipo,
              model: modelRespuesta
            });
          }
          this.respuestas.push({
            id_seccion: seccion.id,
            preguntas: this.dataPreguntas
          });
        }
        this.$q.loading.hide();
        this.showFormulario = true;
        this.selectModalidadPago = false;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.error.ERROR_FORM").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        }).then(result => {
          this.showFormulario = true;
        });
        // if (this.$router.currentRoute.name == "Talleres") {
        //   this.$router.go(0);
        // } else {
        //   this.$router.push({ name: "Talleres" }).catch(err => {});
        // }
      });
  }
  private validarSeccion(index) {
    if (index < 1) {
      this.respuestasApi = [];
    }
    const seccion = this.respuestas[index];
    for (let i = 0; i < seccion.preguntas.length; i++) {
      const pregunta = seccion.preguntas[i];
      if (pregunta.tipo == 0) {
        if (pregunta.obligatorio == 1 && pregunta.model.length == 0) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            allowOutsideClick: false,
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (pregunta.model.length > 0) {
          for (let j = 0; j < this.respuestasApi.length; j++) {
            const respuestasAnteriores = this.respuestasApi[j];
            if (respuestasAnteriores.id_pregunta == pregunta.id_pregunta) {
              this.respuestasApi.splice(j, 1);
            }
          }
          for (let j = 0; j < pregunta.model.length; j++) {
            const opcionMarcada = pregunta.model[j];
            let existe = false;
            for (let k = 0; k < this.respuestasApi.length; k++) {
              if (this.respuestasApi[k].id_respuesta == opcionMarcada) {
                existe = true;
              }
            }
            if (!existe) {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: "",
                id_respuesta: opcionMarcada,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 1) {
        if (pregunta.obligatorio == 1 && pregunta.model == "") {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            allowOutsideClick: false,
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = "";
                this.respuestasApi[j].id_respuesta = pregunta.model;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: "",
                id_respuesta: pregunta.model,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 2) {
        if (
          pregunta.obligatorio == 1 &&
          (!pregunta.model || pregunta.model.trim() == "")
        ) {
          this.$swal({
            icon: "warning",
            allowOutsideClick: false,
            title: "Campos obligatorios",
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = pregunta.model;
                this.respuestasApi[j].id_respuesta = null;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: pregunta.model,
                id_respuesta: null,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 3) {
        if (
          pregunta.obligatorio == 1 &&
          (!pregunta.model || pregunta.model.trim() == "")
        ) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            allowOutsideClick: false,
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        }
        if (pregunta.model != null) {
          let existe = false;
          for (let j = 0; j < this.respuestasApi.length; j++) {
            if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi[j].texto = pregunta.model;
                this.respuestasApi[j].id_respuesta = null;
                this.respuestasApi[j].tipo = pregunta.tipo;
                existe = true;
                break;
              } else {
                this.respuestasApi.splice(j, 1);
                existe = true;
                break;
              }
            }
          }
          if (!existe) {
            if (pregunta.model != null && pregunta.model != "") {
              this.respuestasApi.push({
                id_pregunta: pregunta.id_pregunta,
                texto: pregunta.model,
                id_respuesta: null,
                tipo: pregunta.tipo
              });
            }
          }
        }
      }
      if (pregunta.tipo == 4) {
        var r = false;
        if (this.documentosRespuestas.length > 0) {
          this.documentosRespuestas.reverse();
          for (let x = 0; x < this.respuestasApi.length; x++) {
            if (this.respuestasApi[x].id_pregunta == pregunta.id_pregunta)
              r = true;
          }
          if (r == false) {
            for (let i = 0; i < this.documentosRespuestas.length; i++) {
              if (
                this.documentosRespuestas[i].id_pregunta == pregunta.id_pregunta
              ) {
                this.respuestasApi.push({
                  id_pregunta: this.documentosRespuestas[i].id_pregunta,
                  doc_respuesta: this.documentosRespuestas[i].respuesta_doc,
                  id_respuesta: null,
                  tipo: pregunta.tipo
                });
                if (pregunta.id_pregunta) {
                  this.respuestas_doc.push(pregunta.id_pregunta);
                }
                r = true;
              }
            }
            if (r == false) {
              this.$swal({
                icon: "warning",
                title: "Campos obligatorios",
                allowOutsideClick: false,
                text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
                confirmButtonText: "Aceptar"
              });
              return;
            }
          }
        } else {
          if (pregunta.obligatorio == 1) {
            const found = this.respuestas_doc.find(
              item => item == pregunta.id_pregunta
            );

            if (found == null) {
              this.$swal({
                icon: "warning",
                title: "Campos obligatorios",
                allowOutsideClick: false,
                text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
                confirmButtonText: "Aceptar"
              });
              return;
            }
          }
        }
        // //doble check para casos de error en formulario
        // const found = this.respuestasApi.findIndex(
        //   p => p.id_pregunta == pregunta.id_pregunta
        // );
        // if (found < 0) {
        //   this.respuestasApi.push({
        //     id_pregunta: this.documentosRespuestas[i].id_pregunta,
        //     doc_respuesta: this.documentosRespuestas[i].respuesta_doc,
        //     id_respuesta: null,
        //     tipo: pregunta.tipo
        //   });
        // }
        if (r == false) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            allowOutsideClick: false,
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        }
      }

      if (pregunta.tipo == 5) {
        if (
          pregunta.obligatorio == 1 &&
          (!pregunta.model || pregunta.model.trim() == "")
        ) {
          this.$swal({
            icon: "warning",
            title: "Campos obligatorios",
            allowOutsideClick: false,
            text: 'La pregunta "' + pregunta.nombre + '" es obligatoria',
            confirmButtonText: "Aceptar"
          });
          return;
        } else {
          if (pregunta.model != null) {
            let existe = false;
            for (let j = 0; j < this.respuestasApi.length; j++) {
              if (this.respuestasApi[j].id_pregunta == pregunta.id_pregunta) {
                if (pregunta.model != null && pregunta.model != "") {
                  this.respuestasApi[j].fecha = pregunta.model;
                  this.respuestasApi[j].id_respuesta = null;
                  this.respuestasApi[j].tipo = pregunta.tipo;
                  existe = true;
                  break;
                } else {
                  this.respuestasApi.splice(j, 1);
                  existe = true;
                  break;
                }
              }
            }
            if (!existe) {
              if (pregunta.model != null && pregunta.model != "") {
                this.respuestasApi.push({
                  id_pregunta: pregunta.id_pregunta,
                  fecha: pregunta.model,
                  id_respuesta: null,
                  tipo: pregunta.tipo
                });
              }
            }
          }
        }
      }
    }
    if (index == this.respuestas.length - 1) {
      this.enviarEncuesta();
    } else {
      this.step = this.step + 1;
    }
  }

  private enviarEncuesta() {
    const data = this.respuestasApi.filter(item => item.tipo == 4);
    //vuelve a revisar que no se repitan las preguntas
    const uniqueData = [
      ...new Map(data.reverse().map(m => [m.id_pregunta, m])).values()
    ];
    const formData = new FormData();
    //const files = (this.$refs.uploader as any).getFiles();
    if (uniqueData) {
      uniqueData.forEach((item: any, index: any) =>
        formData.append(item.id_pregunta, item.doc_respuesta)
      );
    }
    this.showFormulario = false;
    this.$q.loading.show({
      delay: 100 // ms
    });
    formData.append("id_taller_horario", this.dataUser.id_taller_horario);
    formData.append("tipo_documento", this.tipoDocumento.toString());
    formData.append("modalidadpago", this.selectedModalidad.value);
    formData.append("respuestas", JSON.stringify(this.respuestasApi));
    if (this.logged == true) {
      formData.append("logged", "si");
    } else {
      formData.append("logged", "no");
    }
    if (this.is_inscribirFamiliar == true) {
      formData.append(
        "inscripcion_familiar",
        JSON.stringify(this.formDataAdicional)
      );
    } else {
      formData.append("inscripcion_familiar", "{}");
    }

    this.$axios
      .post("/responder/formulario", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res: any) => {
        this.$swal({
          icon: "success",
          title: "Completado",
          text:
            "Se ha completado la solicitud de inscripción, pronto recibirás un correo de confirmación.",
          confirmButtonText: "Aceptar"
        }).then(result => {
          this.$q.loading.show({
            delay: 100 // ms
          });
          if (this.$router.currentRoute.name == "MisTalleres") {
            this.$router.go(0);
          } else {
            this.$router.push({ name: "MisTalleres" }).catch(err => {});
          }
        });
        this.$q.loading.hide();
      })
      .catch(err => {
        const message = err.response.data.message;
        if (message == "INVALID_DATOS_RUT") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Por favor ingrese un rut válido.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "USUARIO_REGISTRADO") {
          this.$swal({
            icon: "error",
            title: "Registro",
            text: "Usted ya esta inscrito en este horario",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "INVALID_PARAM_RESPUESTAS") {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text:
              "Debe contestar almenos una pregunta para continuar la inscripción.",
            confirmButtonText: "Aceptar"
          });
        } else if (message == "NO_CUPO") {
          this.$swal({
            icon: "error",
            title: "Registro",
            text: "No hay cupo para este horario.",
            confirmButtonText: "Aceptar"
          }).then(result => {
            if (result.value) {
              this.$router.go(0);
            }
          });
        } else if (message === "INVALID_EDAD") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.invalid_age").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_HOMBRE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.only_men").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_MUJER") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("messages.only_women").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_OTRO") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: "El horario seleccionado es solo para Otros géneros",
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_GENERO_SOLO_NO_CONTESTAR") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text:
              "El horario seleccionado es solo para género  Prefiero no contestar",
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error",
            text: "Ha ocurrido un error, por favor intente nuevamente.",
            confirmButtonText: "Aceptar"
          });
        }
        this.$q.loading.hide();
      });
  }

  private updateFiles(files: any, value: any) {
    if (files) {
      if (this.documentosRespuestas.length == 0) {
        this.documentosRespuestas.push({
          id_pregunta: value,
          respuesta_doc: files
        });
      } else {
        const data = this.respuestasApi.filter(
          item => item.id_pregunta == value
        );
        for (let i = 0; i < this.documentosRespuestas.length; i++) {
          if (this.documentosRespuestas[i].id_pregunta == value) {
            this.documentosRespuestas.splice(i, 1);
            this.documentosRespuestas.push({
              id_pregunta: value,
              respuesta_doc: files
            });
          } else if (this.documentosRespuestas[i].id_pregunta != value) {
            this.documentosRespuestas.push({
              id_pregunta: value,
              respuesta_doc: files
            });
          }
        }
      }
    }
  }

  private cerrar() {
    this.showFormulario = false;
    this.respuestasApi = [];
    this.documentosRespuestas = [];
    this.formulario = [];
    this.respuestas = [];
    this.dataPreguntas = [];
    this.model = [];
    this.showInscribirFamiliar = false;
    (this.formDataAdicional.name = ""),
      (this.formDataAdicional.paterno = ""),
      (this.formDataAdicional.materno = ""),
      (this.formDataAdicional.sexo = null),
      (this.formattedRut = ""),
      (this.formDataAdicional.rut = ""),
      (this.formDataAdicional.email = ""),
      (this.formDataAdicional.calle = ""),
      (this.formDataAdicional.numero = ""),
      (this.formDataAdicional.phone = null);
    this.formattedFecha = "";
    this.formDataAdicional.fecha_nacimiento = null;
    this.dataComuna = {
      value: 108,
      label: "La Reina"
    };
    this.datosTipoGenero = {
      value: null,
      label: "Género"
    };
    this.disabled = true;
    this.step = 1;
    this.selectedModalidad = {
      value: 0,
      label: "Seleccione una modalidad"
    };
  }

  private onRejected(rejectedEntries) {
    // Notify plugin needs to be installed
    // https://quasar.dev/quasar-plugins/notify#Installation
    this.$q.notify({
      type: "negative",
      message: `${rejectedEntries.length} El archivo debe ser formato Jpg, Jpng, Png, Pdf o Docx`
    });
  }

  private closeqdate(i) {
    (this.$refs as any)["date_" + i][0].hide();
  }

  private clearDatadoc(id) {
    for (var i = 0; i < this.respuestas_doc.length; i++) {
      if (this.respuestas_doc[i] === id) {
        this.respuestas_doc.splice(i, 1);
      }
    }
    for (var n = 0; n < this.documentosRespuestas.length; n++) {
      if (this.documentosRespuestas[n].respuesta_doc == null) {
        this.documentosRespuestas.splice(n, 1);
      }
      if (this.documentosRespuestas[n].id_pregunta == id) {
        this.documentosRespuestas.splice(n, 1);
      }
    }
    for (var l = 0; l < this.respuestasApi.length; l++) {
      if (this.respuestasApi[l].id_pregunta == id) {
        this.respuestasApi.splice(l, 1);
      }
    }

    this.respuestasApi = this.respuestasApi.filter(
      item => item.id_pregunta != id
    );
    this.documentosRespuestas = this.documentosRespuestas.filter(
      item => item.id_pregunta != id
    );
    this.respuestas_doc = this.respuestas_doc.filter(item => item != id);
  }
  private inscribirFamiliar(data) {
    this.nombreSelectedTaller = data.nombre;
    this.showInscribirFamiliar = true;
    this.formDataAdicional.email = this.$store.state.user.email;
    this.formDataAdicional.calle = this.$store.state.user.calle;
    this.formDataAdicional.numero = this.$store.state.user.numero;

    if (this.$store.state.user && this.$store.state.user.aclaratoria) {
      this.formDataAdicional.aclaratoria = this.$store.state.user.aclaratoria;
    } else {
      this.formDataAdicional.aclaratoria = "";
    }

    if (this.$store.state.user && this.$store.state.user.phone) {
      this.formDataAdicional.phone = this.$store.state.user.phone;
    } else {
      this.formDataAdicional.phone = null;
    }

    if (this.$store.state.user && this.$store.state.user.comuna) {
      this.formDataAdicional.comuna = this.$store.state.user.comuna;
    } else {
      this.formDataAdicional.comuna = this.dataComuna.label;
    }

    this.horario = false;
    this.is_inscribirFamiliar = true;
    this.inscribeF = true;
  }

  private checkFormulario() {
    if (
      !this.formDataAdicional.rut ||
      (!this.validateRut(this.formDataAdicional.rut) &&
        this.tipoDocumento == 1) ||
      this.formDataAdicional.rut.trim() == ""
    ) {
      let title = "";
      if (this.tipoDocumento == 1) {
        title = this.$t("notifications.error.NO_RUT").toString();
      } else {
        title = "Por favor, ingrese un documento válido";
      }
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: title,
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.tipoDocumento == 1) {
      const rut = this.formDataAdicional.rut.substring(
        0,
        this.formDataAdicional.rut.length - 1
      );
      const dv = this.formDataAdicional.rut.substring(
        this.formDataAdicional.rut.length - 1
      );
      const formattedRut = `${rut}-${dv}`;
      this.formDataAdicional.rut = formattedRut;
    } else {
      const formattedDoc = this.formDataAdicional.rut.replace("-", "");
      this.formDataAdicional.rut = formattedDoc;
    }
    if (
      !this.formDataAdicional.name ||
      this.formDataAdicional.name.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Por favor, ingrese el nombre",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (
      !this.formDataAdicional.paterno ||
      this.formDataAdicional.paterno.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Por favor, ingrese el apellido paterno",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      this.formDataAdicional.fecha_nacimiento == null ||
      !this.checkFecha(this.formDataAdicional.fecha_nacimiento)
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.validFecha(this.formDataAdicional.fecha_nacimiento)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.formDataAdicional.fecha_nacimiento.getFullYear() < 1890) {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
          return;
        }
      }
    }

    if (this.formDataAdicional.sexo == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_GENERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (
      !this.formDataAdicional.email ||
      this.formDataAdicional.email.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.validEmail(this.formDataAdicional.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      this.formDataAdicional.phone == null ||
      this.formDataAdicional.phone <= 99999999
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (
      !this.formDataAdicional.calle ||
      this.formDataAdicional.calle.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_CALLE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.formDataAdicional.numero ||
      this.formDataAdicional.numero.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_NUMERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.formDataAdicional.comuna ||
      this.formDataAdicional.comuna.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Por favor, seleccione una comuna.",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    this.$q.loading.show({
      delay: 100 // ms
    });
    this.loading = true;
    //voy a revisar si el taller tiene formulario
    if (this.dataTaller.tipo_inscripcion == 1) {
      this.getFormulario();
    } else {
      this.inscribirUser();
    }
  }
  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito2(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  private validFecha(date: any) {
    if (date.getFullYear() < 1900) {
      return true;
    } else {
      return false;
    }
  }
  private fillForm(data) {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("ultima/inscripcion?rut=" + data)
      .then((res: any) => {
        if (res.data != "") {
          this.formDataAdicional.calle = res.data.calle;
          this.formDataAdicional.name = res.data.nombre;
          this.formDataAdicional.paterno = res.data.paterno;
          this.formDataAdicional.materno = res.data.materno;
          this.datosTipoGenero.value = res.data.genero;
          this.datosTipoGenero.label = res.data.genero_label;
          this.formDataAdicional.sexo = res.data.genero;
          let splitfecha = res.data.nacimiento.split("-");
          this.formattedFecha =
            splitfecha[2] + "/" + splitfecha[1] + "/" + splitfecha[0];
          this.formDataAdicional.email = res.data.correo;
          this.formDataAdicional.phone = res.data.telefono;
          this.formDataAdicional.calle = res.data.calle;
          this.dataComuna = {
            value: res.data.comuna_id,
            label: res.data.comuna
          };
          this.formDataAdicional.comuna = res.data.comuna;
          this.formDataAdicional.numero = res.data.numero;
          this.formDataAdicional.aclaratoria = res.data.aclaratoria;
        }
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.$swal({
          icon: "error",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.error.ERROR_FORM").toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        }).then(result => {
          this.showFormulario = true;
        });
      });
    this.$q.loading.hide();
  }
}
