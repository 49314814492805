
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    FormValidator
  }
})
export default class Preguntas extends Mixins(FormValidator) {
  private routerGo(value: string) {
    if (value && value != "") {
      if (this.$router.currentRoute.name == value) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: value }).catch(err => {});
      }
    }
  }
}
