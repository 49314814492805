
import { Vue, Component, Prop } from "vue-property-decorator";
import PerfilTaller from "@/components/Talleres/PerfilTaller.vue";

@Component({
  components: {
    PerfilTaller
  }
})
export default class PerfilTallerView extends Vue {
  private mounted() {}
}
