
import { Vue, Component, Prop } from "vue-property-decorator";
import PerfilHorario from "@/components/Talleres/PerfilHorario.vue";

@Component({
  components: {
    PerfilHorario
  }
})
export default class PerfilTallerView extends Vue {
  private mounted() {}
}
